<template>
  <v-col class="p-0 white" :cols="cols">
    <v-row class="m-0">
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 box-title"
          :class="{
            'vuetify-table-header': !isClient,
            'vuetify-table-header-cli': isClient,
          }"
        >
          {{ title }}
        </div>
      </v-col>
    </v-row>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <slot></slot>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  computed: {
    isClient: function () {
      return this.getClient().isClient;
    },
  },
  name: "chart-wrap",
  props: {
    cols: Number,
    title: String,
  }
}
</script>

<style lang="scss" scoped>
.box-title {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 100%;
  text-align: center;
}
</style>
